 <template>
  <DossierAdherant
    :adherant="adherant"
    :wilayas="wilayas"
    :statuts="statuts"
    :secteurs="secteurs"
    :listDossier="listDossier"
    :acceptTermsAndConditions="true"
    :isAdmin="true"
    @update="update($event)"
  />
</template>
 <script>
import { mapActions, mapState, mapGetters } from "vuex";
import DossierAdherant from "@/components/common/dossier_adherant/Index";

export default {
  name: "Test",
  created() {
    this.getAdditioanlData();
    this.getWilayas();
    this.getSecteurs();
    this.getStatuts();
  },
  components: {
    DossierAdherant
  },
  computed: {
    ...mapState({
      adherant: state => state.adherent.adherent,
      wilayas: state => state.wilayas.wilayas,
      secteurs: state => state.secteurs.secteurs,
      statuts: state => state.statuts.statuts
    }),
    ...mapGetters({ listDossier: "getListDossierRequis" })
  },
  methods: {
    update(event) {
        this.updateAdherent(event);
    },
    ...mapActions({
      getAdditioanlData: "fetchAdherent",
      getWilayas: "fetchWilayas",
      getSecteurs: "fetchSecteurs",
      getStatuts: "fetchStatuts",
      updateSoumissionState: "validateAndUpdateAdherentSoumissionState",
      updateAdherent :"updatingAdherent"
    })
  }
};
</script>
 